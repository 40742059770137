import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/CyberSecurity.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Business Protection",
    paragraph: "Keep your business and products safe from criminals.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Constant Surveillance ",
    paragraph: "Ongoing observation from trusted security professionals.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Happier Employees  ",
    paragraph: "A more secure and productive work environment.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Focus on Your Business ",
    paragraph: "Let professionals worry about the security. ",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Accessible Safety  ",
    paragraph: "Access security through convenient modern technology.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Easier Insurance Claims  ",
    paragraph: "Capture breaches on camera for an easier claims process.",
  },
];

export const HeaderData = {
  src: Internet,
  title: "IT Cyber Security",
  subtitle: "Online Threats Can Destroy Your Business",
  paragraph: "Get the protection you need with IT cyber security",
  to: Product_Quote_Routes.CyberSecurity,
};

export const DescriptionContent = {
  title: "Why Do You Need IT Cyber Security?",
  paragraph:
    "Having the necessary IT security features will protect you from hackers, scammers, and phishers. Keep your business safe with efficient IT cyber security solutions. ",
  to: Product_Quote_Routes.CyberSecurity,
  profitPoint1: "Protection from scams and hacking",
  profitPoint2: "Cost-efficient security products",
  profitPoint3: "Technology that’s easy to use",
  profitPoint4: "Peace of mind knowing your business is safe  ",
};

export const FAQS = [
  {
    title: "Why do I need IT cyber security?",
    answer:
      "IT cyber security protects you from hackers, scammers, phishers, and others who wish to breach your business.   ",
  },
  {
    title: "What types of cyber security products are available?",
    answer:
      "There’s a wide range of cyber security products available. These include network security monitoring tools, antivirus software, and encryption tools.     ",
  },
  {
    title: "What cyber security products do I need?",
    answer:
      "There are cyber security options available for every type of business. Consult one of our trusted providers to find out what services you require.   ",
  },
  {
    title: "How much does cyber security cost?",
    answer:
      "The price of cyber security depends on what services and products you use.  ",
  },
  {
    title: "Can’t I watch out for hackers myself?",
    answer:
      "You could take your own steps to protect your business from security threats. But the larger your business, the more likely it is that you need professional security services.    ",
  },
  {
    title: "How does IT cyber security save me money?",
    answer:
      "Cyber security saves you money by protecting you from online attacks and theft that could become extremely costly. It also allows you to increase productivity by leaving the security to professionals.   ",
  },
  {
    title: "How can Digital Comparison get me IT cyber security?",
    answer:
      "We research the top cyber security providers in the country. Then you will receive no-obligation quotes from a variety of suppliers. ",
  },
  {
    title: "Which cyber security provider is the best?",
    answer:
      "All our cyber security providers are capable of supporting your business. Choose the ideal security partner for your business based on your needs, budget, and goals.  ",
  },
];
